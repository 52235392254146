@import 'src/styles/variables';
@import 'src/styles/mixins';

.homePage {
  width: 100%;
  padding-top: 40px;

  @include vp-767 {
    padding-top: 0;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 120px;
  position: relative;

  @include vp-767 {
    gap: 36px;
  }
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 120px;
  position: relative;

  @include vp-767 {
    gap: 36px;
    padding: 20px;
  }
}

.collectionsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.leftBanner {
  left: -200px;
  top: 400px;
  transform: rotate(230deg);
}

.rightBanner {
  right: -480px;
  top: 100px;
  transform: rotate(-120deg);
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
