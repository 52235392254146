@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  @include vp-767 {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.authorName {
  @include text-16;
  font-weight: 600;
}

div.bg {
  right: -350px;
  top: -180px;

  @include vp-767 {
    right: -200px;
    top: 0;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 12px;

  &Tag {
    height: 46px;
  }
}

.desc {
  @include text-16;
  white-space: pre-wrap;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 586px;
  width: 586px;

  @include vp-767 {
    width: 100%;
    gap: 20px;
    min-width: 100%;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;

  @include vp-767 {
    border-radius: 6px;
  }
}

.images {
  position: relative;
  height: 582px;
  width: 100%;

  @include vp-767 {
    height: 330px;
  }
}

div.imagesTop {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 426px;
  height: 426px;

  @include vp-767 {
    width: 240px;
    height: 240px;
    right: 0;
    left: -100px;
    margin: 0 auto;
  }
}

div.imagesBottom {
  top: 0;
  right: 0;
  position: absolute;
  width: 426px;
  height: 426px;
  z-index: -1;

  @include vp-767 {
    width: 240px;
    height: 240px;
    right: -100px;
    left: 0;
    margin: 0 auto;
  }
}

.arrow {
  transform: rotate(180deg);
}

.author {
  display: flex;
  gap: 12px;
  padding: 8px 12px;
  align-items: center;
  backdrop-filter: blur(16px);
  background: $grayBorder;
  border-radius: 8px;
  width: fit-content;

  &Img {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }

  &Name {
    @include text-16;
  }
}

.button {
  &:hover {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.name {
  @include text-40;

  @include vp-767 {
    @include text-30;
  }
}

.timer {
  display: flex;
  gap: 10px;

  &Item {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 56px;
    height: 56px;
    @include text-20;
    font-weight: 600;
    border-radius: 12px;
    background: $grayBorder;
    backdrop-filter: blur(16px);
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 24px;
}
