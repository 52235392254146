@import 'src/styles/variables';
@import 'src/styles/mixins';

div.card {
  width: 520px;
  min-width: 520px;

  @include vp-767 {
    width: 100%;
    min-width: 100%;
    border: none;
    padding: 0;
  }
}

img.cardImg {
  height: 420px;
  object-fit: cover;

  @include vp-767 {
    width: 100%;
    height: 180px;
    margin-bottom: 12px;
  }
}

.icon {
  transform: rotate(180deg);
}

.card {
  &Mini {
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;

    @include vp-767 {
      flex-direction: row-reverse;
      justify-content: flex-end;
      width: 100%;
      gap: 12px;
    }

    &Info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 370px;

      @include vp-767 {
        width: 100%;
        gap: 4px;
      }
    }

    &Tag {
      color: $primary;
      @include text-18;
      text-transform: uppercase;
    }

    &Title {
      @include text-24;
      overflow: hidden;
      display: -webkit-box;
      white-space: wrap;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @include vp-767 {
        @include text-20;
      }
    }

    &Img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid $grayBorder;

      &Wrapper {
        width: 300px;
        height: 190px;
        position: relative;

        @include vp-767 {
          width: 92px;
          height: 92px;
          min-width: 92px;
        }
      }
    }
  }
}
