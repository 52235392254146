@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.slider {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-top: 20px !important;
}

.footer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.buttons {
  display: flex;
  gap: 30px;

  @include vp-767 {
    justify-content: space-between;
    width: 100%;
  }
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
}

.arrowButton {
  padding: 16px;
  border-radius: 50px;
  border: 1px solid $grayBorder;
  background: rgba(14, 14, 14, 0.2);
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
}

.bullet {
  transition: width 0.5s;
  width: 10px;
  height: 10px;
  border-radius: 32px;
  background-color: $white;
  opacity: 0.2;
  display: block;
  cursor: pointer;
}

.next {
  transform: rotate(180deg);

  @include vp-767 {
    margin-right: 10px;
  }
}

.bulletActive {
  transition: width 0.5s;
  width: 26px;
  opacity: 1;
}
