@import 'src/styles/variables';
@import 'src/styles/mixins';

.banner {
  width: 100%;
  height: 590px;
  position: relative;
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;
  padding: 60px;

  @include vp-767 {
    height: 520px;
    padding: 20px;
    border-radius: 0;
  }

  &Img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    border-radius: 20px 20px 30px 30px;
    object-fit: cover;

    @include vp-767 {
      border-radius: 0;
    }
  }

  &Button {
    &:hover {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &Info {
    z-index: 0;
    color: $white;
  }

  &Title {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;

    @include vp-767 {
      @include text-24;
    }
  }

  &Author {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 12px 8px 8px;
    backdrop-filter: blur(16px);
    background: $grayBorder;
    border-radius: 12px;
    margin-bottom: 24px;
    width: fit-content;

    @include vp-767 {
      margin-bottom: 16px;
    }

    &Img {
      width: 30px;
      height: 30px;
      border-radius: 8px;
    }

    &Name {
      @include text-16;
      font-weight: 600;
    }
  }

  &Price {
    @include vp-767 {
      @include text-14;
    }

    &Item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        opacity: 0.5;
      }

      @include vp-767 {
        gap: 4px;
      }

      &Row {
        display: flex;
        gap: 8px;
        align-items: center;
        color: $white;

        span {
          opacity: 1;
        }

        @include vp-767 {
          gap: 4px;
          font-weight: 700;
        }
      }
    }
  }

  &Gradient {
    background: linear-gradient(
      180deg,
      rgba(14, 14, 14, 0) 21.41%,
      rgba(14, 14, 14, 0.8) 68.89%,
      #0e0e0e 91.18%
    );
    height: 540px;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: -1;
  }
}

.arrow {
  transform: rotate(180deg);
}

.gallery {
  overflow: auto;

  @include vp-767 {
    padding: 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &Item {
    width: 100%;
    height: 180px;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;

    @include vp-767 {
      min-width: 160px;
      height: 110px;
    }

    &Active {
      border: 1px solid rgba(255, 255, 255, 1);
    }

    &Img {
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &Title {
      padding: 12px;
      @include text-24;
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(15px);

      @include vp-767 {
        padding: 8px;
        @include text-14;
      }
    }
  }
}
