@import 'src/styles/variables';
@import 'src/styles/mixins';

.exploreBlockContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;

  @include vp-767 {
    flex-direction: column;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 60px;

  @include vp-767 {
    margin-top: 0;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include vp-767 {
    gap: 24px;
  }
}

.heading {
  width: 532px;

  @include vp-767 {
    width: 100%;
  }
}

.highlight {
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 66px;
  text-transform: none;
  font-family: SpaceGrotesk;
  color: $primary;

  @include vp-767 {
    @include text-40;
  }
}

.subheading {
  @include text-20;
  max-width: 324px;

  @include vp-767 {
    max-width: none;
    @include text-20;
    font-weight: 600;
  }
}

.infoContainer {
  display: flex;
  flex-direction: row;
  gap: 40px;

  @include vp-767 {
    gap: 12px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include vp-767 {
    gap: 8px;
    padding: 16px;
    border: 1px solid #ffffff4d;
    backdrop-filter: blur(12px);
    border-radius: 12px;
    min-width: 210px;
  }
}

.infoTitle {
  font-family: 'SpaceGrotesk', sans-serif;
  @include text-30;
  font-weight: bold;
  width: min-content;
}

.infoDescription {
  @include text-18;
  color: $gray;
  max-width: 170px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;

  @include vp-767 {
    flex-direction: column;
  }
}

button.button {
  width: 200px;

  @include vp-767 {
    width: 100%;
  }
}

.cardsContainer {
  min-width: 562px;
  max-width: 562px;
  height: 620px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 1;

  @include vp-767 {
    display: none;
  }
}

.cardsContainerMob {
  display: none;

  @include vp-767 {
    width: 100%;
    height: 320px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.card1 {
  position: absolute;
  bottom: 20px;
  z-index: 20;

  @include vp-767 {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 50px 0 auto;
    display: flex;
    justify-content: center;
  }
}

.card2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  @include vp-767 {
    left: 0;
    right: 0;
    margin: 0 auto 0 50px;
    display: flex;
    justify-content: center;
  }
}
