@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  padding-right: 12px;
  padding-top: 16px;
}

.content {
  width: 100%;
  border-radius: 16px;
  border: 1px solid $grayBorder;
  background: rgba(29, 31, 36, 0.4);
  backdrop-filter: blur(30px);
  padding: 20px;
  color: $white;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;

  &:hover {
    color: $dark;
    background: $white;
  }
}

.tag {
  position: absolute;
  display: flex;
  align-items: center;
  right: -12px;
  top: -16px;
  @include text-18;
  padding: 12px 16px;
  border-radius: 50px;
  border: 1px solid white;
  background: $black;
  color: $white;
  height: 48px;
}

.media {
  border-radius: 12px;
  height: 196px;
  object-fit: cover;
}

.text {
  @include text-16;
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
  height: 144px;
}

.profile {
  display: flex;
  gap: 20px;
  align-items: center;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}

.links {
  display: flex;
  align-items: center;
  gap: 12px;
}

.link {
  width: 18px;
  height: 18px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  height: 106px;
}

.name {
  @include text-18;
  font-weight: 600;
}

.about {
  @include text-16;
  margin-bottom: 12px;
  color: $gray;
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon {
  width: 18px;
  height: 18px;
}
