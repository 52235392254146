@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 18px;
  border: 1px solid var(--white-border);
  backdrop-filter: blur(100px);
  z-index: 0;

  @include vp-767 {
    width: 225px;
    height: 270px;
    padding: 10px;
    border-radius: 12px;
    gap: 10px;
  }
}

.dark {
  background-color: transparent;
}

.light {
  background-color: $white;
}

.imageContainer {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  position: relative;

  @include vp-767 {
    height: 200px;
  }
}

.image {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: space-between;

  &Dark {
    color: $black;
  }
}

.bidInfo,
.auctionInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include vp-767 {
    gap: 6px;
  }
}

.text {
  @include text-16;

  @include vp-767 {
    font-size: 8px;
    line-height: 13px;
  }
}

.textDark {
  color: rgba(255, 255, 255, 0.3);
}

.textLight {
  color: rgba(14, 14, 14, 0.6);
}

.bidAmount {
  display: flex;
  align-items: center;
  gap: 9px;

  @include vp-767 {
    gap: 5px;
  }
}

.cryptoIcon {
  width: 29px;
  height: 29px;

  @include vp-767 {
    width: 16px;
    height: 16px;
  }
}

.value {
  font-size: 27px;
  font-weight: bold;

  @include vp-767 {
    @include text-14;
  }
}

.countdown {
  font-size: 27px;
  font-weight: bold;

  @include vp-767 {
    @include text-14;
  }
}
