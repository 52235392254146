@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  cursor: pointer;

  @include vp-767 {
    gap: 0px;
    border-radius: 16px;
    border: 1px solid $grayBorder;
    padding: 16px;
  }
}

.media {
  width: 100%;
  height: 330px;
  object-fit: cover;
  border-radius: 14px;
  border: 1px solid $grayBorder;

  @include vp-767 {
    width: 100%;
    height: 200px;
    margin-bottom: 24px;
    border-radius: 12px;
    border: none;
  }
}

.title {
  @include text-24;
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;

  @include vp-767 {
    margin-bottom: 4px;
    @include text-20;
  }
}

.tag {
  color: $primary;
  @include text-18;
  text-transform: uppercase;

  @include vp-767 {
    margin-bottom: 4px;
    @include text-16;
  }
}

.desc {
  @include text-18;
  @include textDimmed;
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  @include vp-767 {
    margin-bottom: 4px;
    @include text-14;
  }
}

.mediaWrapper {
  position: relative;
  width: 520px;
  height: 420px;

  @include vp-767 {
    width: 100%;
    height: 180px;
  }
}
